<template>

</template>
<script>
import api from '../assets/api.js'
import { EventBus } from '../EventBus.js';
import { mapGetters } from 'vuex';


export default {
    name: 'PhoneRoute',
    data() {
        return {

        };
    },
    created() {
        const phone = this.extractTokenFromURL();
        this.openContactdetails(phone);

    },
    computed: {
        ...mapGetters({
            properties: "propertiesStore/filtered",
        })
    },
    methods: {
        extractTokenFromURL() {
            const url = window.location.href;
            const phone = url.match(/\/phone\/([^/]+)/);
            if (phone && phone[1]) {
                return phone[1];
            }
            return null;
        },
        async openContactdetails(phone) {
            let PhoneNumberCheck = /^\d{10,11}$/
            if (!PhoneNumberCheck.test(phone)) {
                this.$router.push('/404');
                return;
            }

            try {
                let r = await api.get(this, api.CONTACTS + 'check', {
                    id: undefined,
                    phone: phone
                });

                let contactId = r?.contacts[0]?.id
                let leasesCount = r?.contacts[0]?.Leases?.length
                let contact = r?.contacts[0]

                if (contact.status === 'Pending') {
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === contact?.Pending[0]?.Unit?.property_id));
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: contact?.Pending[0]?.Unit?.id });

                    let lead = contact.Leads.find((item) => {
                        return item?.Lease?.id == contact.Pending[0]?.id
                    })
                    var data = {
                        id: lead.id,
                        contact_id: contact.id,
                        lead_first: contact.first,
                        lead_last: contact.last,
                        lease_id: contact?.Pending[0]?.id,
                        unit_id: contact?.Pending[0]?.Unit?.id,
                        selectedLeadId: lead.id
                    }
                    this.$router.push('/leads');
                    EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                } else if (contact.status === 'Active Lead') {
                    if (contact.Reservations.length > 0) {
                        await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === contact?.Reservations[0]?.Lease?.Unit?.property_id));

                        let lead = contact.Leads.find((item) => {
                            return item?.lease_id == contact.Reservations[0]?.lease_id
                        })
                        var data = {
                            id: lead?.id,
                            contact_id: contact.id,
                            lead_first: contact.first,
                            lead_last: contact.last,
                            lease_id: contact?.Reservations[0]?.lease_id,
                            reservation_id: contact?.Reservations[0]?.id,
                            reservation_time: contact?.Reservations[0]?.time,
                            unit_id: contact?.Reservations[0]?.Unit?.id,
                            selectedLeadId: lead?.id
                        }
                        this.$router.push('/leads');
                        EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                    } else {
                        var data = {
                            id: contact?.Leads[0]?.id,
                            contact_id: contact.id,
                            lead_first: contact.first,
                            lead_last: contact.last,
                            unit_id: contact?.Leads[0]?.unit_id,
                            selectedLeadId: contact?.Leads[0]?.id
                        }
                        this.$router.push('/leads');
                        EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                    }
                } else if (contact.status === 'Delinquent') {
                    this.$router.push(`/contacts/${contactId}`);
                } else if (contactId && leasesCount > 0) {
                    this.$router.push(`/contacts/${contactId}`);
                } else if (leasesCount === 0 && contact.Leads.length === 0) {
                    var data = {
                        contact_id: contact.id,
                    }
                    this.$router.push('/leads');
                    EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                } else {
                    this.$router.push('/404');
                }
            } catch (error) {
                this.$router.push('/leads');
                EventBus.$emit('new_lead',{location:'create_new_lead'});
                console.log("error", error)
            }
        }
    }
};
</script>